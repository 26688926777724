@import "../../GlobalStyles/variables.scss";
@import "../../GlobalStyles/animationStyles.scss";

.app-container {
    overflow: hidden;
    position: relative;
    height: 100%;
    background-color: $primaryBlack;
    overflow-y: auto;
}

.app-logo {
    opacity: 0;
    position: absolute;
    top: 16px;
    left: 16px;
    height: 40px;
    width: 40px;
}
