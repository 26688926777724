@import "./variables.scss";

@mixin ani-ease-in {
    animation-timing-function: cubic-bezier(0, 1.2, 0.2, 1);
}

@mixin ani-ease-in-safe {
    animation-timing-function: cubic-bezier(0, 1, 0, 1);
}

@mixin ani-ease-out {
    animation-timing-function: cubic-bezier(1, 0, 1, 0);
}

@mixin ani-bg {
    transition-property: background-color color fill;
    transition-duration: 0.35s;
}

// Module Mount
@mixin ani-download-mount {
    @include ani-ease-in-safe;
    animation-name: downloadMountDesktop;
    @include media-phone-three-seventy-five {
        animation-name: downloadMountMobile;
    }
    animation-duration: $downloadAniLength;
    animation-play-state: running;
}

@keyframes downloadMountMobile {
    0% {
        transform: translateY(180px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes downloadMountDesktop {
    0% {
        transform: translateX(180px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

// Unmount

@mixin ani-download-unMount {
    animation-timing-function: cubic-bezier(0.5, 0, 0, 1);
    animation-name: downloadUnMountDesktop;
    animation-duration: $downloadAniLength;
    animation-play-state: running;
    @include media-phone-three-seventy-five {
        animation-name: downloadUnMountMobile;
    }
}

@keyframes downloadUnMountDesktop {
    0% {
        transform: translateX(0);
        // opacity: 1;
    }
    100% {
        transform: translateX(100vw);
        // opacity: 0;
    }
}

@keyframes downloadUnMountMobile {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(100vh);
    }
}

// Generic slide up
@mixin ani-viewport-active-y($aniDelay) {
    @include ani-ease-in;
    animation-name: viewPortActiveY;
    animation-duration: $moveInDuration;
    animation-delay: $aniDelay;
    animation-play-state: running;
    animation-fill-mode: forwards;
}

@keyframes viewPortActiveY {
    0% {
        opacity: 0;
        transform: translateY(200px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.viewport-animation-y {
    opacity: 0;
    @include ani-viewport-active-y(0);
}

// Generic slide down
@mixin ani-viewport-active-y-rev($aniDelay) {
    @include ani-ease-in;
    animation-name: viewPortActiveYRev;
    animation-duration: $moveInDuration;
    animation-delay: $aniDelay;
    animation-play-state: running;
    animation-fill-mode: forwards;
}

@keyframes viewPortActiveYRev {
    0% {
        opacity: 0;
        transform: translateY(-200px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.viewport-animation-y-rev {
    opacity: 0;
    @include ani-viewport-active-y-rev(0s);
}

// Generic move in from left
@mixin ani-viewport-active-x($aniDelay) {
    @include ani-ease-in;
    animation-name: viewPortActiveX;
    animation-duration: $moveInDuration;
    animation-delay: $aniDelay;
    animation-play-state: running;
    animation-fill-mode: forwards;
}

@keyframes viewPortActiveX {
    0% {
        opacity: 0;
        transform: translateX(-200px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.viewport-animation-x {
    opacity: 0;
    @include ani-viewport-active-x(0);
}

// Generic move in from right
@mixin ani-viewport-active-x-rev($aniDelay) {
    @include ani-ease-in;
    animation-name: viewPortActiveXRev;
    animation-duration: $moveInDuration;
    animation-delay: $aniDelay;
    animation-play-state: running;
    animation-fill-mode: forwards;
}

@keyframes viewPortActiveXRev {
    0% {
        opacity: 0;
        transform: translateX(200px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.viewport-animation-x-rev {
    opacity: 0;
    @include ani-viewport-active-x-rev(0);
}

// Generic move in from right
// Animate white bg pulse thing
$aniLoaderTiming: 1.3s;
@mixin ani-pulse($delay) {
    animation-delay: $delay;
    animation-name: aniPulse;
    animation-duration: $aniLoaderTiming;
    animation-delay: 0.5s;
    animation-play-state: running;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
}

@keyframes aniPulse {
    0% {
        transform: translate(-50%, -50%) scale(0);
        // border: 1px solid $primaryBrand;
        background-color: $primaryBlackTwo;
        opacity: 20px;
    }
    100% {
        opacity: 0;
        background-color: $primaryBlackTwo;
        transform: translate(-50%, -50%) scale(1);
    }
}

@mixin ani-push-in-out {
    animation-name: aniPushInOut;
    animation-duration: $aniLoaderTiming;
    animation-delay: 0.5s;
    animation-play-state: running;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    @include ani-ease-in;
}

@keyframes aniPushInOut {
    0% {
        transform: scale(1);
    }
    10% {
        transform: scale(1);
    }
    20% {
        transform: scale(0.8);
    }
    100% {
        transform: scale(1);
    }
    // 100% {
    //     transform: scale(1);
    // }
}

@mixin ani-scale-down {
    animation-name: aniScaleDown;
    animation-duration: 0.5s;
    animation-play-state: running;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(1, 0, 0, 1);
}

@mixin ani-scale-up {
    animation-name: aniScaleUp;
    animation-duration: 0.75s;
    animation-delay: 0.25s;
    animation-play-state: running;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0, 0.76, 0.25, 1);
}

@keyframes aniScaleDown {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(0);
    }
}

@keyframes aniScaleUp {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    50% {
        transform: scale(1.5);
    }
    // 90% {
    //     transform: scale(1.5);
    // }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.ani-scale {
    &-down {
        @include ani-scale-down;
    }
    &-up {
        @include ani-scale-up;
    }
}
