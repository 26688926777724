@import "../../GlobalStyles/layoutStyles.scss";
@import "../../GlobalStyles/shadowStyles.scss";

.icon-list-container {
    display: flex;
    flex-direction: row;
}

.slide-up-screenshot-image {
    height: 911px;
    width: 421px;
    @include media-phone {
        height: 659px;
        width: 305px;
    }
}

.how-work-screenshot {
    padding: 128px 0 0 0;
    @include media-phone {
        padding: 0;
    }
    &-image {
        opacity: 0;
        height: 911px;
        width: 421px;
        @include media-phone {
            height: 659px;
            width: 305px;
            position: absolute;
            right: -32px;
            top: calc(100vh * 0.8);
        }
    }
    // flex-shrink: 0;
    // flex-grow: 0;
}

.how-work-bottom-spacing {
    padding: 0 0 320px 0;
}

.how-work-ani-header {
    opacity: 0;
}

.how-work-ani-list {
    opacity: 0;
}

.ani-bullets {
    opacity: 0;
}
