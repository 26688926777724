// Sizes
@mixin font-size-thirty-six {
    font-size: 36px;
}

@mixin font-size-twenty-four {
    font-size: 24px;
}

@mixin font-size-sixteen {
    font-size: 16px;
    // @include font-weight-seven;
}

@mixin font-size-twelve {
    font-size: 12px;
}

// Weights
@mixin font-weight-three {
    font-weight: 300;
}

@mixin font-weight-four {
    font-weight: 400;
}

@mixin font-weight-five {
    font-weight: 500;
}

@mixin font-weight-seven {
    font-weight: 700;
}

@mixin font-weight-eight {
    font-weight: 800;
}

@mixin font-weight-nine {
    font-weight: 900;
}

// Limit lines
@mixin font-limit-line($numberOfLines) {
    text-overflow: ellipsis;
    -webkit-line-clamp: $numberOfLines;
    word-wrap: break-word;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}


