// Primary & Secondary
// $primaryBlack: #14161a;
$primaryBlack: #0d0f11;
$primaryBlackTwo: #232a37;
$primaryBlackThree: #131920;
$footerBg: #1d222c;
$primaryBlackDark: #0d0f11;
$primaryGreyText: #7d899b;
$primaryWhite: #eff2f5;
$primaryBlue: #8abdff;

// Brand
$primaryBrand: #30e8ab;

// Outlines
$outlineGrey: #28292b;

// Screenshot Border Radius
$screenshotBorderRadius: 40px;

// Download Modal BG
$downloadModalBg: rgba(35, 42, 55, 0.9);
$downloadModalBgDarker: rgba(20, 22, 26, 1);
// Download Animation Length
$downloadAniLength: 0.375s;

// Move in Duration
$moveInDuration: 2s;
