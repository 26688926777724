@import "../../GlobalStyles/iconStyles.scss";

.bullet-list-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 16px 0 0 0;
}

.bullet-icon-container {
    flex-shrink: 0;
    background-color: $primaryBlackTwo;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    width: 44px;
    border-radius: 12px;
}

.bullet-icon {
    height: 20px;
    width: 20px;
}

.bullet-text {
    margin-left: 16px;
    max-width: 388px;
}
