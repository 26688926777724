// @use "../../GlobalStyles/colorStyles.scss";
// @import "../../GlobalStyles/fontStyles.scss";
// Test
@use "../../GlobalStyles/colorStyles";
@use "../../GlobalStyles/fontStyles";

.download-button-container {
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 24px;
    @include colorStyles.blur-buttons;
    flex: 1;
    &:not(:first-child) {
        margin-left: 8px;
    }
    @include colorStyles.green-hover("path");
}

.download-text {
    @include fontStyles.font-size-sixteen;
    @include fontStyles.font-weight-seven;
}
