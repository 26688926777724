@import "../../GlobalStyles/layoutStyles.scss";
@import "../../GlobalStyles/variables.scss";
@import "../../GlobalStyles/fontStyles.scss";

.footer-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 32px 12px 32px;
    margin: -100px 0 0 0;
    background-color: $footerBg;
    @include media-phone {
        margin: 240px 0 0 0;
    }
}

.footer-tags {
    opacity: 0;
    overflow: visible;
    flex-wrap: wrap;
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @include font-limit-line(1);
}

.footer-links {
    flex: 1;
    margin: 32px 0;
    @include media-phone {
        width: 100%;
        align-items: center;
    }
}

.footer-copyright {
    opacity: 0;
    &:not(:first-child) {
        opacity: 1;
        margin: 8px 0 8px 12px;
        border-left: 1.5px solid $primaryGreyText;
        padding: 0 0 0 12px;
    }
    @include media-phone {
        width: 100%;
        text-align: center;
    }
    @include font-size-twelve;
}

.thank-you-footer {
    opacity: 0;
}
