@mixin media-phone {
    @media (max-width: 1000px) {
        @content;
    }
}

@mixin media-phone-three-seventy-five {
    @media (max-width: 700px) {
        @content;
    }
}

@mixin media-phone-smallest {
    @media (max-width: 320px) {
        @content;
    }
}

.main-view-container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100vh;
    @include media-phone {
        flex-direction: column;
        align-items: center;
    }
}

.column-container {
    display: flex;
    justify-content: center;
    // align-items: center;
    flex-direction: column;
    width: 440px;
    flex-shrink: 0;
    padding: 0 80px 0 20px;
    &-right {
        margin-left: 100px;
    }
    @include media-phone {
        height: 100vh;
        padding: 0 20px 0 20px;
        width: fit-content;
        margin: 0;
    }
}

@mixin global-left-margin {
    padding-left: 80px;
}

@mixin global-right-margin {
    // padding-right: 236px;
}

@mixin global-right-margin-mobile {
    padding-right: 20px;
}

@mixin global-left-margin-mobile {
    padding-left: 20px;
}

// Used for app store download icons
.app-store-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    width: 36px;
}
