@import "../../GlobalStyles/layoutStyles.scss";
@import "../../GlobalStyles/variables.scss";
@import "../../GlobalStyles/shadowStyles.scss";
@import "../../GlobalStyles/colorStyles.scss";

.why-screenshot {
    padding: 128px 0 0 0;
    @include media-phone {
        padding: 0;
    }
    &-image {
        opacity: 0;
        height: 911px;
        width: 421px;
        @include media-phone {
            height: 659px;
            width: 305px;
            position: absolute;
            right: -32px;
            top: calc(100vh * 0.7);
        }
    }
    // flex-shrink: 0;
    // flex-grow: 0;
}

.why-action-icons {
    margin: 12px 0 0 0;
    display: flex;
    align-self: flex-start;
    align-items: center;
    padding: 12px;
    border: 1.5px solid $primaryBlackTwo;
    border-radius: 12px;
}

.why-bottom-spacing {
    padding: 0 0 300px 0;
}

.why-header-ani {
    opacity: 0;
}
