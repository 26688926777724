@import "../../GlobalStyles/layoutStyles.scss";
@import "../../GlobalStyles/shadowStyles.scss";
@import "../../GlobalStyles/variables.scss";
@import "../../GlobalStyles/animationStyles.scss";

.above-fold-screenshot {
    @include media-phone {
        padding: 0;
    }
    &-image {
        opacity: 0;
        &-ani {
            @include ani-viewport-active-y(0);
        }
        align-self: flex-start;
        height: 911px;
        width: 421px;
        padding: 440px 0 0 0;
        @include media-phone {
            padding: 0;
            height: 659px;
            width: 305px;
            position: absolute;
            right: -32px;
            top: calc(100vh * 0.7);
        }
    }
}

.main-headline {
    opacity: 0;
    &-ani {
        @include ani-viewport-active-x(0);
    }
}

.above-fold-bottom-spacing {
    padding: 0 0 300px 0;
}
