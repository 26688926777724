@import "./variables.scss";

.path-fill-white path {
    fill: $primaryWhite;
}

.path-fill-black path {
    fill: $primaryBlack;
}

.path-fill-grey path {
    fill: $primaryGreyText;
}

.path-fill-brand path {
    fill: $primaryBrand;
}

.path-stroke-white path {
    stroke: $primaryWhite;
}

.path-stroke-black path {
    stroke: $primaryBlack;
}

.path-stroke-grey path {
    stroke: $primaryGreyText;
}

.path-stroke-brand path {
    stroke: $primaryBrand;
}

.rek-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 12px;
    width: 12px;
}

.rek-bullet-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16px;
    width: 16px;
}
