@import "../../GlobalStyles/colorStyles.scss";
@import "../../GlobalStyles/layoutStyles.scss";
@import "../../GlobalStyles/fontStyles.scss";
@import "../../GlobalStyles/animationStyles.scss";

.download-container {
    @include ani-download-mount;
    &-close-ani {
        @include ani-download-unMount;
    }
    padding: 20px;
    box-sizing: border-box;
    position: fixed;
    height: 100%;
    min-width: 375px;
    max-width: 100vw * 0.35;
    top: 0;
    right: 0;
    z-index: 1000;
    @include dark-blur-darker;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include media-phone-three-seventy-five {
        min-width: 320px;
        max-width: 100%;
    }
}

.close-x {
    margin: 0 0 12px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 200px;
    height: 60px;
    width: 60px;
    @include blur-buttons;
    @include green-hover("stroke");
}

.download-update-date {
    @include font-size-twelve;
    color: $primaryGreyText;
}

.download-paragraph {
    @include font-size-sixteen;
}

.download-multi-button-container {
    margin: 32px 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.close-button {
    margin: 20px 0 0 0;
}

.slide-scrollable {
    display: flex;
    flex-direction: column;
    &-center {
        justify-content: center;
    }
    overflow-y: auto;
    padding: 0 12px;
    flex: 1;
}

.privacy-policy-text {
    @include font-size-twelve;
}
