@import url("https://fonts.googleapis.com/css?family=Inter:300,400,500,700,900");
// @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;800;900&display=swap");
// family=Poppins:wght@0,100;0,300;1,300&display=swap
@import "./fontStyles.scss";
@import "./variables.scss";
@import "./layoutStyles.scss";
@import "./animationStyles.scss";
@import "./colorStyles.scss";

body {
    margin: 0;
    font-family: "Inter", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // background-color: $primaryBlack;
    background-color: $primaryBlackTwo;
    color: $primaryWhite;
    // letter-spacing: 0.04rem;
    letter-spacing: .75px;
    line-height: 1.3;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
}

body::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1 {
    margin: 0;
    @include font-size-thirty-six;
    @include font-weight-three;
    padding-bottom: 8px;
}

h2 {
    margin: 0;
    @include font-size-twenty-four;
    @include font-weight-three;
    padding-bottom: 8px;
}

strong {
    @include font-weight-eight;
}

p {
    margin: 8px 0 0 0;
    @include font-size-twenty-four;
    @include font-weight-five;
    line-height: 1.2em;
    // letter-spacing: .4px;
}

a {
    color: $primaryBrand;
    text-decoration: underline;
    cursor: pointer;
    @include ani-bg;
    &:hover {
        @include ani-bg;
        color: $primaryBlue;
        @include dark-blur;
    }
}
