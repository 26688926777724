@import "../../GlobalStyles/fontStyles.scss";
@import "../../GlobalStyles/variables.scss";
@import "../../GlobalStyles/shadowStyles.scss";

.rek-row-container {
    @include font-size-twelve;
    display: flex;
    width: 408px;
    flex-direction: row;
    align-items: center;
    &:not(:first-child) {
        margin: 24px 0 0 0;
    }
    // border: 1.5px solid $outlineGrey;
    @include glow-drop-shadow;
    background-color: $primaryBlackDark;
    padding: 16px;
    border-radius: 24px;
}

.rek-row-image {
    height: 55px;
    width: 44px;
    border-radius: 0 8px 8px 8px;
    object-fit: cover;
    margin-right: 12px;
}

.rek-row-second-column {
    display: flex;
    flex-direction: column;
}

.rek-row-title-fav-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 0 4px 0;
}

.rek-row-favicon {
    height: 16px;
    width: 16px;
}

.rek-row-title {
    margin-left: 8px;
    @include font-weight-seven;
    @include font-size-sixteen;
    @include font-limit-line(1);
}

.rek-row-description {
    @include font-limit-line(1);
    color: $primaryGreyText;
}

.rek-icons {
    margin: 4px 0 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.rek-icon-text {
    margin: 0 8px 0 4px;
    @include font-size-twelve;
    color: $primaryGreyText;
}
