@use "variables";
@use "animationStyles";
// @import "./animationStyles.scss";

.black {
    &-bg {
        background-color: variables.$primaryBlack;
    }
    &-fg {
        color: variables.$primaryBlack;
    }
}

.grey {
    &-bg {
        background-color: variables.$primaryBlackTwo;
    }
    &-fg {
        color: variables.$primaryBlackTwo;
    }
    &-text {
        color: variables.$primaryGreyText;
    }
}

.white {
    &-bg {
        background-color: variables.$primaryWhite;
    }
    &-fg {
        color: variables.$primaryWhite;
    }
}

.brand {
    &-bg {
        background-color: variables.$primaryBrand;
    }
    &-fg {
        color: variables.$primaryBrand;
    }
}

@mixin dark-blur {
    // background: rgba(35, 42, 55, 0.3);
    background: variables.$downloadModalBg;
    backdrop-filter: blur(12px);
}

@mixin dark-blur-darker {
    // background: rgba(35, 42, 55, 0.3);
    background: variables.$downloadModalBgDarker;
    backdrop-filter: blur(12px);
}

@mixin blur-buttons {
    background: rgba(255, 255, 255, 0.02);
    // backdrop-filter: blur(36px);
}

@mixin green-hover($pathOrStroke) {
    @include animationStyles.ani-bg;
    &:hover {
        @include animationStyles.ani-bg;
        cursor: pointer;
        background-color: variables.$primaryBrand;
        color: variables.$primaryBlackTwo;
        path {
            @if $pathOrStroke == "path" {
                fill: variables.$primaryBlackTwo;
            } @else {
                stroke: variables.$primaryBlackTwo;
            }
        }
    }
}
