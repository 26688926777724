@import "../../GlobalStyles/colorStyles.scss";
@import "../../GlobalStyles/variables.scss";
@import "../../GlobalStyles/animationStyles.scss";
@import "../../GlobalStyles/fontStyles.scss";

.page-loader-container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: $primaryBlack;
    z-index: 100000;
    @include font-size-twelve;
    color: $primaryWhite;
    &-hide {
        display: none;
    }
}

.page-loader-icon {
    @include ani-push-in-out;
}

$pulseSize: 100vh;
.page-loader-pulse {
    position: absolute;
    border-radius: 9999px;
    top: 50%;
    left: 50%;
    height: $pulseSize;
    width: $pulseSize;
    &-ani {
        @include ani-pulse(0s);
    }
}
