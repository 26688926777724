@import "../../GlobalStyles/variables.scss";
@import "../../GlobalStyles/fontStyles.scss";
@import "../../GlobalStyles/layoutStyles.scss";

.category-container {
    opacity: 0;
    margin: 12px 0 0 0;
    display: flex;
    flex-direction: row;
}

.category-tag {
    flex-shrink: 0;
    @include font-size-twelve;
    border-radius: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    background-color: $primaryBlackTwo;
    &:not(:first-child) {
        margin-left: 8px;
    }
    &-light {
        background-color: $primaryWhite;
        color: $primaryBlack;
        @include font-weight-seven;
    }
}

.tagline-text {
    opacity: 0;
    max-width: 352px;
    @include media-phone {
        max-width: max-content;
    }
}

.whats-rek-examples {
    opacity: 0;
    @include media-phone {
        padding: 0;
    }
    @include media-phone {
        position: absolute;
        right: -100px;
        top: calc(100vh * 0.7);
    }
}

.whats-rek-bottom-spacing {
    padding: 0 0 80px 0;
}
