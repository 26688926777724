@import "../../GlobalStyles/variables.scss";
@import "../../GlobalStyles/fontStyles.scss";
@import "../../GlobalStyles/animationStyles.scss";
@import "../../GlobalStyles/colorStyles.scss";
@import "../../GlobalStyles/layoutStyles.scss";

.link-tag-container {
    text-align: center;
    background-color: $primaryBlackTwo;
    padding: 12px 20px;
    border-radius: 200px;
    letter-spacing: 1px;
    &:not(:last-child) {
        margin: 0 12px 0 0;
        @include media-phone-smallest {
            margin: 0 0 12px 0;
        }
    }
    @include green-hover("path");
}

.link-tag-floating {
    opacity: 0;
    @include dark-blur;
    position: fixed;
    right: 4px;
    top: 16px;
    z-index: 100;
}
